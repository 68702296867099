import Pages from '@/components/pages/Pages.vue';
import PrivacyPolicy from '@/components/pages/PrivacyPolicy.vue';
import TermsOfUse from '@/components/pages/TermsOfUse.vue';
import DataSecurity from '@/components/pages/DataSecurity.vue';
import CookiesPolicy from '@/components/pages/CookiesPolicy.vue';
import UnsplashPolicy from '@/components/pages/UnsplashLibrary.vue';
// import WelcomePage from '@/components/pages/welcome/index.vue';

export default [
  {
    path: '/pages',
    component: Pages,
    children: [
      {
        path: '',
        component: PrivacyPolicy,
      },
      {
        path: 'privacy_policy',
        component: PrivacyPolicy,
        name: 'privacy_policy',
        meta: { publicPage: true },
      },
      {
        path: 'terms_of_use',
        component: TermsOfUse,
        name: 'terms_of_use',
        meta: { publicPage: true },
      },
      {
        path: 'data_security',
        component: DataSecurity,
        name: 'data_security',
        meta: { publicPage: true },
      },
      {
        path: 'cookies_policy',
        component: CookiesPolicy,
        name: 'cookies_policy',
        meta: { publicPage: true },
      },
      {
        path: 'unsplash',
        component: UnsplashPolicy,
        name: 'unsplash',
      },
    ],
  },
  // {
  //   path: '/welcome',
  //   component: WelcomePage,
  //   name: 'welcome_page'
  // }
];
