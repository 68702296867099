<template>
  <div class="container">
    <h1>Unsplash test</h1>

    <div class="d-flex mb-5">
      <input v-model="query" type="text" class="form-control" />
      <button type="button" class="btn btn-primary ml-3" @click="search">
        Search
      </button>
    </div>

    <div class="row">
      <div class="col-4">
        <div v-for="image in images" :key="image.id" class="mb-5">
          <h4>ID: {{ image.id }}</h4>
          <img class="img-thumbnail" :src="image.urls.thumb" /><br />
          <button class="btn btn-primary btn-sm" @click="show(image.id)">
            Show
          </button>
        </div>
      </div>
      <div v-if="current" class="col-8">
        <div class="mb-5">
          <h2>Main program</h2>
          <img
            class="img-fluid"
            :src="current.urls.raw + '&w=1920&h=556&fit=crop&crop=faces'"
          />
        </div>

        <div class="mb-5">
          <h2>Tiled view</h2>
          <img
            class="img-fluid"
            :src="current.urls.raw + '&w=360&h=186&fit=crop&crop=faces'"
          />
        </div>

        <div class="mb-5">
          <h2>Mobile Web</h2>
          <img
            class="img-fluid"
            :src="current.urls.raw + '&w=375&h=280&fit=crop&crop=faces'"
          />
        </div>

        <div class="mb-5">
          <h2>Table / Client App View</h2>
          <img
            class="img-fluid"
            :src="current.urls.raw + '&w=250&h=250&fit=crop&crop=faces'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createApi } from 'unsplash-js';

const unsplash = createApi({
  accessKey: 'p7u48EptrKWrI-oJEdDb6l4Y93v5gSn6pmQsRTu-sIg',
});

export default {
  data: () => ({
    query: '',
    images: [],
    current: null,
  }),

  mounted() {
    this.search();

    // unsplash.photos.list({ page: 2, perPage: 15 });
  },

  methods: {
    search() {
      if (this.query) {
        unsplash.search
          .getPhotos({
            query: this.query,
            page: 1,
            perPage: 10,
            orientation: 'landscape',
          })
          .then(({ response }) => {
            // console.log(result);
            this.images = response.results;
          });
      } else {
        unsplash.photos.list({ page: 1, perPage: 10 }).then(({ response }) => {
          // console.log(result);
          this.images = response.results;
        });
      }
    },

    show(id) {
      unsplash.photos.get({ photoId: id }).then(({ response }) => {
        // console.log(response);
        this.current = response;
      });
    },
  },
};
</script>
