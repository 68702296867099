import { mapState, mapGetters } from 'vuex';
import { HTTP, HttpErrorsHandling } from '@/utils/http-common';
import { cloneDeep } from 'lodash-es';
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus';
import required from 'vuelidate/lib/validators/required';
import CountryControl from '@/components/auth/controls/country-control/index.vue';
import StateControl from '@/components/auth/controls/state-control/index.vue';

// @vue/component
export default {
  name: 'CardForm',

  components: {
    StripeElements,
    StripeElement,
    CountryControl,
    StateControl,
  },
  
  props: {
    /**
     * Value
     * @type {boolean} - type Boolean
     */
    value: Boolean,

    /**
     * Mode
     * @type {string} - type String
     * @default 'add' - default value = 'add'
     * @description variants: 'add', 'edit', 'edit-partial', 'rewrite'
     */
    mode: {
      type: String,
      default: 'add', // add, edit, edit-parital, rewrite
    },

    /**
     * Card
     * @type {object} - type Object
     */
    card: {
      type: Object,
    },
  },

  data() {
    return {
      showRequiredMarker: true,
      invalidFeedback: null,
      busy: false,
      brand: 'unknown',
      number: false,
      expiry: false,
      cvc: false,
      numberError: false,
      expiryError: false,
      cvcError: false,
      numberFocus: false,
      expiryFocus: false,
      cvcFocus: false,
      stripeKey: import.meta.env.VITE_STRIPE_KEY,
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:500&display=swap',
          },
        ],
      },
      stripeOptions: {
        style: {
          base: {
            color: '#22242f',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            fontSmoothing: 'antialiased',
            lineHeight: '22px',

            '::placeholder': {
              color: '#b0bdc9',
            },
          },
          invalid: {
            color: '#DE3D54',

            '::placeholder': {
              color: '#DE3D54',
            },
          },
        },
      },
      billing: {
        address: {
          country: null,
          state: null,
          city: '',
          postal_code: '',
        },
      },
    };
  },

  computed: {
    isComplete() {
      if (
        this.number &&
        this.expiry &&
        this.cvc &&
        this.billing.address.country !== null &&
        this.billing.address.city &&
        this.billing.address.postal_code
      ) {
        return ['US', 'CA'].includes(this.billing.address.country)
          ? this.billing.address.state !== null
          : true;
      } else {
        return false;
      }
    },

    ...mapState(['currentUser']),
    ...mapGetters('settings', ['stateNameByCode']),
  },

  validations: {
    billing: {
      address: {
        country: {
          required,
        },
        state: {
          required() {
            if (['US', 'CA'].includes(this.billing.address.country)) {
              return (
                this.billing.address.state !== null &&
                this.billing.address.state.trim() !== ''
              );
            }
            return true;
          },
        },
        city: {
          required,
        },
        postal_code: {
          required,
        },
      },
    },
  },

  watch: {
    'billing.address.country': function (newValue, oldValue) {
      if (oldValue !== null) {
        this.billing.address.state = null;
      }
    },

    isComplete: {
      handler: function (v) {
        this.$emit('input', v);
      },
      immediate: true,
    },

    mode: {
      handler: function () {
        if (['edit', 'edit-partial'].includes(this.mode) && this.card) {
          this.$v.$touch();

          this.billing.address = this.card.billing_info.address
            ? cloneDeep(this.card.billing_info.address)
            : this.billing.address;

          this.billing.address.state = this.stateNameByCode(
            this.billing.address.country,
            this.billing.address.state
          ).length
            ? this.billing.address.state
            : null;

          this.billing.phone = cloneDeep(this.card.billing_info.phone);

          this.number = true;
          this.expiry = true;
          this.cvc = true;
        }
      },
      immediate: true,
    },
  },

  methods: {
    editPaymentMethod() {
      this.$v.$touch();

      if (!this.$v.$error) {
        this.invalidFeedback = null;

        this.billing.address.state =
          this.billing.address.state === null ? '' : this.billing.address.state;

        return new Promise((resolve, reject) => {
          HTTP.patch(`stripe/card/${this.card.id}`, {
            billing_details: this.billing,
          })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              HttpErrorsHandling(error);
              reject();
            });
        });
      }
    },

    addPaymentMethod() {
      this.$v.$touch();

      if (!this.$v.$error) {
        this.busy = true;
        this.invalidFeedback = null;

        const groupComponent = this.$refs.elms;
        const cardComponent = this.$refs.cardNumber;

        // Get stripe element
        const cardElement = cardComponent.stripeElement;

        return new Promise((resolve, reject) => {
          HTTP.get('stripe/card_init')
            .then(({ data }) => {
              const clientSecret = data.client_secret;

              groupComponent.instance
                .confirmCardSetup(clientSecret, {
                  payment_method: {
                    card: cardElement,
                    billing_details: {
                      address: this.billing.address,
                      name: this.currentUser.full_name,
                      email: this.currentUser.email,
                      phone: this.billing.phone,
                    },
                  },
                })
                .then((result) => {
                  if (result.error) {
                    this.invalidFeedback = result.error.message;

                    this.$notify({
                      group: 'alerts',
                      type: 'error',
                      title: result.error.message,
                    });

                    this.busy = false;

                    reject();
                  } else {
                    resolve(result);
                  }
                })
                .catch(reject);
            })
            .catch((error) => {
              HttpErrorsHandling(error);

              this.busy = false;

              reject();
            });
        });
      }
    },
  },
};
